<template>
  <div class="eh-participant-component">
    <div class="eh-flex-container">
      <fp-input
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('base.basic.filter')"
        @change="filterList"
        v-model="filter"
      />
      <fp-select
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('partner.participantList.questionnairesTab.state')"
        :items="Object.values(formStatuses)"
        valueKey="Value"
        @change="filterList"
        v-model="stateFilter"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
      <fp-date-range-button
        :label="$t('partner.participantList.questionnairesTab.fromDate')"
        v-model="addDateFilter"
        @change="filterList"
      />
    </div>
    <fp-table-list
      :bordered="true"
      :hasPagination="true"
      :items="filteredQuestionnaireList"
      :fields="questionnaireListFields"
      sortBy="CreatedAt"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #ListFilterElement="element">
        {{ $t(element.Text) }}
      </template>
      <template #Buttons="row">
        <!-- <b-button
          :disabled="row.item.Status.includes('Kiértékel')"
          class="filter-operation"
          >Figyelmeztetés</b-button
        > -->
        <!-- Bezárás -->
        <b-icon
          v-b-tooltip.hover="$t('base.basic.close')"
          style="cursor: pointer"
          v-if="row.detailsShowing"
          class="h3"
          icon="x-circle-fill"
          @click="$set(row.item, '_showDetails', false)"
        ></b-icon>
        <!-- Megtekintés -->
        <b-icon
          v-if="
            ![formStatuses.UnderEvaluation.Value].includes(row.item.Status) &&
              !row.detailsShowing
          "
          v-b-tooltip.hover="$t('base.basic.view')"
          style="cursor: pointer"
          class="h3"
          icon="eye-fill"
          @click="viewForm(row.item)"
        ></b-icon>
        <!-- Kérdőív értékelés -->
        <b-iconstack
          class="h3"
          v-if="
            [$enums.EvaluationMethod.Manual].includes(
              row.item.EvaluationMethod
            ) && !row.detailsShowing
          "
          v-b-tooltip.hover="
            $t(
              'partner.participantList.questionnairesTab.evaluatingButton'
            )
          "
          :style="
            [
              formStatuses.InProgress.Value,
              formStatuses.Evaluated.Value,
            ].includes(row.item.Status)
              ? 'color:#00000044'
              : 'cursor:pointer'
          "
          @click="evaluating(row.item)"
        >
          <b-icon stacked icon="file-fill"></b-icon>
          <b-icon
            stacked
            icon="check"
            style="color: white"
            scale="0.9"
          ></b-icon>
        </b-iconstack>
        <!-- Ismételt kiadás -->
        <b-iconstack
          class="h3"
          v-b-tooltip.hover="
            $t('partner.participantList.questionnairesTab.giveAgain')
          "
          :style="
            [formStatuses.InProgress.Value].includes(row.item.Status)
              ? 'color:#00000044'
              : 'cursor:pointer'
          "
          @click="
            [formStatuses.InProgress.Value].includes(row.item.Status)
              ? ''
              : reAssign(row.item)
          "
        >
          <b-icon stacked icon="file-fill"></b-icon>
          <b-icon
            stacked
            icon="arrow-counterclockwise"
            style="color: white"
            scale="0.6"
          ></b-icon>
        </b-iconstack>
        <!-- Visszavonás -->
        <b-iconstack
          v-b-tooltip.hover="
            $t('partner.participantList.questionnairesTab.cancelButton')
          "
          class="h3"
          @click="
            ![formStatuses.InProgress.Value].includes(row.item.Status)
              ? ''
              : cancelQuestionnaire(row.item.FormInstanceId)
          "
          :style="
            ![formStatuses.InProgress.Value].includes(row.item.Status)
              ? 'color:#ff000044'
              : 'color:#ff0000;cursor:pointer'
          "
          rotate="45"
        >
          <b-icon stacked icon="circle"></b-icon>
          <b-icon stacked icon="circle" scale="0.78"></b-icon>
          <b-icon stacked icon="circle" scale="0.88"></b-icon>
          <b-icon stacked icon="dash" scale="1.4" shift-v="-0.6"></b-icon>
          <b-icon stacked icon="dash" scale="1.35" shift-v="0.6"></b-icon>
        </b-iconstack>
        <!-- TODO: kell a noti? -->
        <!-- <fp-confirm-notification
          v-model="row.item.StartCancel"
          @confirm-accept="
            row.item.Status.includes('Filled')
              ? ''
              : cancelQuestionnaire(row.item.FormInstanceId)
          "
          title="Kérdőív visszavonás"
          message="Biztosan visszavonja a kérdőívet? A törlés végleges és az esetleges kitöltött adatok elvesznek! Folytatja a visszavonást?"
          acceptButtonText="Igen, vissza szeretném vonni a kérdőívet"
          cancelButtonText="Nem, nem szeretném visszavonni a kérdőívet"
        >
          <b-iconstack rotate="45" @click="$set(row.item, 'StartCancel', true)">
            <b-icon stacked icon="circle"></b-icon>
            <b-icon stacked icon="circle" scale="0.78"></b-icon>
            <b-icon stacked icon="circle" scale="0.88"></b-icon>
            <b-icon stacked icon="dash" scale="1.4" shift-v="-0.6"></b-icon>
            <b-icon stacked icon="dash" scale="1.35" shift-v="0.6"></b-icon>
          </b-iconstack>
        </fp-confirm-notification> -->
      </template>
      <template #ProgressPercentage="row">
        <b-progress :value="row.item.FillingRate" :max="100"></b-progress>
        <div style="text-align: center">
          {{ row.item.FillingRate ? row.item.FillingRate + "%" : "0%" }}
        </div>
      </template>
      <template #row-details="row">
        <b-card v-if="row.item">
          <fp-form-schema-builder
            :formInstanceId="row.item.FormInstanceId"
            :disabled="true"
            :scoreable="
              ![
                formStatuses.InProgress.Value,
                formStatuses.Evaluated.Value,
              ].includes(row.item.Status) && row.item.isEvaluation
            "
            @closeForm="closeForm(row.item)"
          />
        </b-card>
      </template>
      <template #Status="row">
        {{ $t(formStatuses[row.item.Status].Text) }}
      </template>
      <template #CreatedAt="row">
        {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import moment from "moment";
import { FormLogic } from "../../../Logic/Backend/form";
export default {
  data() {
    return {
      filteredQuestionnaireList: [],
      questionnaireList: [],
      questionnaireListFields: [
        {
          key: "Title",
          label: this.$t(
            "partner.participantList.questionnairesTab.questionnaire"
          ),
        },
        {
          key: "CreatedAt",
          label: this.$t(
            "partner.participantList.questionnairesTab.fromDate"
          ),
          sortable: true,
        },
        {
          key: "ProgressPercentage",
          label: this.$t(
            "partner.participantList.questionnairesTab.progress"
          ),
        },
        /* { key: "Deadline", label: "Határidő" }, */
        {
          key: "Status",
          label: this.$t(
            "partner.participantList.questionnairesTab.state"
          ),
        },
        {
          key: "Buttons",
          label: this.$t(
            "partner.participantList.questionnairesTab.operations"
          ),
        },
      ],
      filter: null,
      stateFilter: null,
      addDateFilter: null,
      formStatuses: this.$enums.QuestionnaireStatus,
    };
  },
  props: {
    participant: Object,
  },
  watch: {
    //participant prop változás figyelő
    async participant() {
      //kérdőívek flista frissítés
      await this.getQuestionnaires();
    },
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterList() {
      this.filteredQuestionnaireList = this.questionnaireList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterList(
          this.filter,
          this.questionnaireList,
          ["Title"],
          this.$i18n.locale
        );
      }
      if (this.stateFilter) {
        this.filteredQuestionnaireList = this.filteredQuestionnaireList.filter(
          (task) => task.Status == this.stateFilter
        );
      }
      if (this.addDateFilter) {
        this.filteredQuestionnaireList = this.filteredQuestionnaireList.filter(
          (task) => {
            if (this.addDateFilter.from && this.addDateFilter.to) {
              return moment(task.CreatedAt).isBetween(
                this.addDateFilter.from,
                this.addDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.addDateFilter.from) {
              return moment(task.CreatedAt).isSameOrAfter(
                moment(this.addDateFilter.from)
              );
            } else if (this.addDateFilter.to) {
              return moment(task.CreatedAt).isSameOrBefore(
                moment(this.addDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
    evaluating(rowItem) {
      if (
        ![
          this.formStatuses.InProgress.Value,
          this.formStatuses.Evaluated.Value,
        ].includes(rowItem.Status)
      ) {
        this.$set(rowItem, "isEvaluation", true);
        this.$set(rowItem, "_showDetails", true);
      }
    },
    viewForm(rowItem) {
      this.$set(rowItem, "_showDetails", true);
      this.$set(rowItem, "isEvaluation", false);
    },
    async closeForm(rowItem) {
      this.$set(rowItem, "_showDetails", false);
      await this.getQuestionnaires();
    },
    //kérdőív lista kérés
    async getQuestionnaires() {
      //kérés indítása
      const questionnaireListResponse = await FormLogic.getFormInstanceHeader({
        SubjectId: this.participant.UserId,
        FormCategory: "Questionnaire",
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //lista frissítése (és ideiglenesen szűrés kiválasztott beteg id alapján)
        this.questionnaireList = this.filteredQuestionnaireList = questionnaireListResponse;
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "partner.participantList.questionnairesTab.errorName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //kérdőív újra kiosztás
    async reAssign(instance) {
      const body = {
        FormSchemaId: instance.FormSchemaId,
        FormElements: [
          {
            SubjectId: this.participant.UserId,
            AssigneeId: this.participant.UserId,
            EvaluatorId: this.$loggedUser.UserId,
          },
        ],
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      };
      const assignResponse = await FormLogic.createFormInstance(body);
      if (!assignResponse.Code) {
        //sikeres
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successReassigned"),
          {
            title: this.$t("base.basic.add"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        //hiba
        this.$bvToast.toast(assignResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorReassigned"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      await this.getQuestionnaires();
    },
    async cancelQuestionnaire(instanceId) {
      const cancelQuestionnaireResponse = await FormLogic.deleteFormInstance(
        instanceId
      );
      if (!cancelQuestionnaireResponse.Code) {
        //sikeres
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successCancel"),
          {
            title: this.$t("base.basic.cancellation"),
            variant: "info",
            solid: true,
          }
        );
      } else {
        //hiba
        this.$bvToast.toast(cancelQuestionnaireResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorCancel"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      await this.getQuestionnaires();
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kérdőívek lista betöltése
    await this.getQuestionnaires();
  },
};
</script>
<style scoped>
.filter-operation {
  margin-right: 10px;
}
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
